import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../services/authService';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="flex flex-col h-screen font-ttnorms bg-gray-100 p-8">
      <h1 className="text-2xl font-bold mb-4">Bienvenue sur le tableau de bord Macadamia Staff!</h1>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white shadow-lg p-4 flex flex-col items-center justify-center rounded-lg">
          <p>Utilisateurs</p>
          <p className="text-lg font-semibold">123</p>
        </div>
        <div className="bg-white shadow-lg p-4 flex flex-col items-center justify-center rounded-lg">
          <p>Licences</p>
          <p className="text-lg font-semibold">456</p>
        </div>
        <div className="bg-white shadow-lg p-4 flex flex-col items-center justify-center rounded-lg">
          <p>Dossiers</p>
          <p className="text-lg font-semibold">789</p>
        </div>
        <div className="bg-white shadow-lg p-4 flex flex-col items-center justify-center rounded-lg">
          <p>CA</p>
          <p className="text-lg font-semibold">€1000</p>
        </div>
      </div>
      <button
        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded self-start"
        onClick={handleLogout}
      >
        Déconnexion
      </button>
    </div>
  );
};

export default Dashboard;