import React from 'react';
import { format } from 'date-fns';
import { IFolderMetadata } from '../../../types/folderMetadata';

interface FolderMetadataProps {
    metadata: IFolderMetadata | null;
}

const FolderMetadata: React.FC<FolderMetadataProps> = ({ metadata }) => {
    if (!metadata) {
        return null;
    }
    return (
        <div className="mt-8 space-y-8">
            {/* Section Métadonnées de base */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Métadonnées de base</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                        <div><span className="text-gray-600">Titre:</span> {metadata.metadata.title}</div>
                        <div><span className="text-gray-600">Type:</span> {metadata.metadata.type}</div>
                        <div><span className="text-gray-600">Domaine:</span> {metadata.metadata.domain}</div>
                        <div><span className="text-gray-600">Sous-domaine:</span> {metadata.metadata.subDomain}</div>
                    </div>
                    <div className="space-y-2">
                        <div><span className="text-gray-600">Niveau:</span> {metadata.metadata.level}</div>
                        <div><span className="text-gray-600">Mise à jour:</span> {format(metadata.metadata.lastUpdated, 'dd/MM/yyyy')}</div>
                        <div><span className="text-gray-600">Langue:</span> {metadata.metadata.language}</div>
                    </div>
                </div>
            </div>

            {/* Section Structure */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Structure du contenu</h2>
                <div className="space-y-4">
                    {metadata.structure.sections.map(section => (
                        <div key={section.id} className="border-l-4 border-blue-500 pl-4">
                            <h3 className="font-medium">{section.title}</h3>
                            <p className="text-gray-600 text-sm">{section.type}</p>
                            <div className="mt-2 space-y-2">
                                {section.subsections.map(subsection => (
                                    <div key={subsection.id} className="ml-4">
                                        <h4 className="text-sm font-medium">{subsection.title}</h4>
                                        {subsection.concepts.length > 0 && (
                                            <p className="text-sm text-gray-500">
                                                Concepts: {subsection.concepts.join(', ')}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Section Références légales */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Références légales</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h3 className="text-lg font-medium mb-2">Codes</h3>
                        <div className="space-y-4">
                            {metadata.legalReferences.codes.map((code, index) => (
                                <div key={index} className="border-l-4 border-gray-200 pl-4">
                                    <h4 className="font-medium">{code.code}</h4>
                                    <div className="mt-2 space-y-1">
                                        {code.articles.map((article, articleIndex) => (
                                            <div key={articleIndex} className="text-sm">
                                                <span className="font-medium">{article.number}</span>: {article.description}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-lg font-medium mb-2">Jurisprudence</h3>
                        <div className="space-y-2">
                            {metadata.legalReferences.jurisprudence.map((item, index) => (
                                <div key={index} className="border-l-4 border-gray-200 pl-4">
                                    <p className="font-medium">{item.reference}</p>
                                    <p className="text-sm text-gray-600">{item.theme}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Section Analyse pédagogique */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Analyse pédagogique</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h3 className="text-lg font-medium mb-2">Éléments pédagogiques</h3>
                        <div className="space-y-2">
                            <div><span className="text-gray-600">Difficulté:</span> {metadata.pedagogicalElements.difficulty}</div>
                            <div>
                                <span className="text-gray-600">Prérequis:</span>
                                <ul className="list-disc ml-5 mt-1">
                                    {metadata.pedagogicalElements.prerequisites.map((prereq, index) => (
                                        <li key={index}>{prereq}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <span className="text-gray-600">Objectifs:</span>
                                <ul className="list-disc ml-5 mt-1">
                                    {metadata.pedagogicalElements.learningObjectives.map((objective, index) => (
                                        <li key={index}>{objective}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-lg font-medium mb-2">Qualité du contenu</h3>
                        <div className="space-y-2">
                            <div className="flex items-center">
                                <span className="text-gray-600 w-32">Complétude:</span>
                                <div className="w-full bg-gray-200 rounded h-2">
                                    <div
                                        className="bg-blue-500 rounded h-2"
                                        style={{ width: `${metadata.contentQuality.completeness}%` }}
                                    ></div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="text-gray-600 w-32">Clarté:</span>
                                <div className="w-full bg-gray-200 rounded h-2">
                                    <div
                                        className="bg-blue-500 rounded h-2"
                                        style={{ width: `${metadata.contentQuality.clarity}%` }}
                                    ></div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="text-gray-600 w-32">Précision:</span>
                                <div className="w-full bg-gray-200 rounded h-2">
                                    <div
                                        className="bg-blue-500 rounded h-2"
                                        style={{ width: `${metadata.contentQuality.technicalAccuracy}%` }}
                                    ></div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="text-gray-600 w-32">Pertinence:</span>
                                <div className="w-full bg-gray-200 rounded h-2">
                                    <div
                                        className="bg-blue-500 rounded h-2"
                                        style={{ width: `${metadata.contentQuality.practicalRelevance}%` }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FolderMetadata;