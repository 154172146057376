import React, { useEffect, useState } from 'react';
import Pagination from '../../common/Pagination';
import { format } from 'date-fns';

interface User {
  _id: string;
  email: string;
  civilite: string;
  firstName: string;
  lastName: string;
  pays: string;
  role: string;
  createdAt: Date;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const limit = 20;

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user.token;

        if (!token) {
          throw new Error('Token non trouvé');
        }

        const response = await fetch(
          `${process.env.REACT_APP_URL_API_MACADAMIA}/users?page=${page}&limit=${limit}&sort=createdAt&order=desc&search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Erreur HTTP ! statut : ${response.status}`);
        }

        const data = await response.json();
        setUsers(data.users || []);
        setTotalUsers(data.total || 0);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
        setError('Erreur lors de la récupération des utilisateurs');
        setUsers([]);
        setTotalUsers(0);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [page, limit, searchTerm]);

  // Réinitialiser la page à 1 lorsque le terme de recherche change
  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  return (
    <div className="p-8">
      <h1 className="text-xl mb-4">Utilisateurs ({totalUsers})</h1>

      <input
        type="text"
        className="mb-4 p-2 border border-gray-300 rounded-lg focus:border-green-300 w-full max-w-md"
        placeholder="Recherche..."
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />

      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {users.length > 0 ? (
            <div className="overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3 px-6">Email</th>
                    <th scope="col" className="py-3 px-6">Civilité</th>
                    <th scope="col" className="py-3 px-6">Prénom</th>
                    <th scope="col" className="py-3 px-6">Nom</th>
                    <th scope="col" className="py-3 px-6">Pays</th>
                    <th scope="col" className="py-3 px-6">Inscription</th>
                    <th scope="col" className="py-3 px-6">Rôle</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user._id} className="bg-white border-b hover:bg-gray-50">
                      <td className="py-4 px-6">{user.email || '-'}</td>
                      <td className="py-4 px-6">{user.civilite || '-'}</td>
                      <td className="py-4 px-6">{user.firstName || '-'}</td>
                      <td className="py-4 px-6">{user.lastName || '-'}</td>
                      <td className="py-4 px-6">{user.pays || '-'}</td>
                      <td className="py-4 px-6">
                        {user.createdAt ? format(new Date(user.createdAt), 'dd/MM/yyyy') : '-'}
                      </td>
                      <td className="py-4 px-6">{user.role || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-4 text-gray-500">
              Aucun utilisateur trouvé
            </div>
          )}
        </>
      )}

      <Pagination
        page={page}
        limit={limit}
        totalUsers={totalUsers}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Users;
