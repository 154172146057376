import React from 'react';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/users/Users';
import Folders from '../pages/folders/Folders';
import FolderDetails from '../pages/folders/FolderDetails';
import Licences from '../pages/Licences';
import Payments from '../pages/payments/Payments';
import Logs from '../pages/logs/Logs';
import Settings from '../pages/settings/Settings';

export interface RouteConfig {
    path: string;
    element: JSX.Element;
    title: string;
}

export const routes: RouteConfig[] = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        title: "Dashboard"
    },
    {
        path: "/users",
        element: <Users />,
        title: "Utilisateurs"
    },
    {
        path: "/folders",
        element: <Folders />,
        title: "Dossiers"
    },
    {
        path: "/folders/:id",
        element: <FolderDetails />,
        title: "Détails du dossier"
    },    
    {
        path: "/licences",
        element: <Licences />,
        title: "Licences"
    },
    {
        path: "/payments",
        element: <Payments />,
        title: "Paiements"
    },
    {
        path: "/logs",
        element: <Logs />,
        title: "Logs"
    },
    {
        path: "/settings",
        element: <Settings />,
        title: "Paramètres"
    }
];