import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import { routes, RouteConfig } from './components/routes/routes';
import LoadingSpinner from './components/common/LoadingSpinner';

// Lazy loading du composant Login
const Login = React.lazy(() => import('./components/pages/auth/Login'));

function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route index element={<Login />} />
          </Route>
          <Route element={<PrivateRoute />}>
            {routes.map((route: RouteConfig) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    </AuthProvider>
  );
}

export default App;