import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserInfoDisplay from '../../common/UserInfoDisplay';
import { format } from 'date-fns';
import FolderMetadata from './FolderMetadata';
import { IFolderMetadata } from '../../../types/folderMetadata';

interface Folder {
    _id: string;
    title: string;
    author: string;
    description: string;
    createdAt: Date;
    settings: {
        visibility: string;
    };
    metadata: IFolderMetadata | null;
}

const FolderDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [folder, setFolder] = useState<Folder | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [generatingMetadata, setGeneratingMetadata] = useState(false);
    const [metadataError, setMetadataError] = useState<string | null>(null);

    const defaultMetadata: IFolderMetadata = {
        metadata: {
            title: '∅',
            type: '∅',
            domain: '∅',
            subDomain: '∅',
            level: '∅',
            lastUpdated: new Date(),
            language: '∅',
        },
        classification: {
            mainCategory: '∅',
            subCategories: [],
            nature: '∅',
        },
        legalConcepts: {
            primary: [],
            secondary: [],
        },
        structure: {
            sections: [],
        },
        legalReferences: {
            codes: [],
            jurisprudence: [],
        },
        relatedConcepts: [],
        semanticAnalysis: {
            keyPhrases: [],
            definitions: [],
        },
        practicalApplications: [],
        pedagogicalElements: {
            difficulty: '∅',
            prerequisites: [],
            learningObjectives: [],
        },
        contentQuality: {
            completeness: 0,
            clarity: 0,
            technicalAccuracy: 0,
            practicalRelevance: 0,
        },
        tags: {
            primary: [],
            secondary: [],
            technical: [],
            practical: []
        }
    };

    useEffect(() => {
        const fetchFolderDetails = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user') || '{}');
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des détails du dossier');
                }

                const data = await response.json();
                setFolder(data);

                const metadataResponse = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/dossier-metadata/${id}/metadata`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                );

                if (metadataResponse.ok) {
                    const metadataData = await metadataResponse.json();
                    console.log('Données des métadonnées récupérées :', metadataData); // Ajout du console.log
                    setFolder(prev => prev ? { ...prev, metadata: metadataData } : null);
                } else if (metadataResponse.status === 404) {
                    console.log('Aucune métadonnée trouvée pour ce dossier.'); // Optionnel : log pour le cas 404
                    setFolder(prev => prev ? { ...prev, metadata: null } : null);
                } else {
                    const errorData = await metadataResponse.json();
                    console.error('Erreur lors de la récupération des métadonnées :', errorData);
                    throw new Error(errorData.message || 'Erreur lors de la récupération des métadonnées');
                }

            } catch (error) {
                console.error('Erreur:', error);
                setError(error instanceof Error ? error.message : 'Une erreur est survenue');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchFolderDetails();
        }
    }, [id]);

    const handleGenerateMetadata = async () => {
        try {
            setGeneratingMetadata(true);
            setMetadataError(null);

            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const response = await fetch(
                `${process.env.REACT_APP_URL_API_MACADAMIA}/dossier-metadata/${id}/metadata`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la génération des metadata');
            }

            const data = await response.json();
            setFolder(prev => prev ? {
                ...prev,
                metadata: data.metadata
            } : null);

            console.log(`Metadata ${folder?.metadata ? 'régénérées' : 'générées'} avec succès`);

        } catch (error) {
            console.error('Erreur lors de la génération des metadata:', error);
            setMetadataError(error instanceof Error ? error.message : 'Une erreur est survenue');
        } finally {
            setGeneratingMetadata(false);
        }
    };

    if (loading) {
        return (
            <div className="p-8 animate-pulse">
                <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
                <div className="space-y-4">
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-8">
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
                    {error}
                </div>
            </div>
        );
    }

    if (!folder) {
        return (
            <div className="p-8">
                <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded">
                    Dossier non trouvé
                </div>
            </div>
        );
    }

    return (
        <div className="p-8">
            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-platypi">{folder.title}</h1>
                    <button
                        onClick={handleGenerateMetadata}
                        disabled={generatingMetadata}
                        className={`
                            px-4 py-2 rounded-md text-white font-medium
                            ${generatingMetadata
                                ? 'bg-gray-400 cursor-not-allowed'
                                : folder.metadata 
                                    ? 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800'
                                    : 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800'
                            }
                            transition-colors duration-200
                        `}
                    >
                        {generatingMetadata ? (
                            <>
                                <span className="inline-block animate-spin mr-2">⚙️</span>
                                {folder.metadata ? 'Régénération...' : 'Génération...'}
                            </>
                        ) : (
                            folder.metadata ? 'Régénérer les metadata' : 'Générer les metadata'
                        )}
                    </button>
                </div>

                {metadataError && (
                    <div className="mb-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
                        {metadataError}
                    </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h2 className="text-lg font-medium mb-3">Informations générales</h2>
                        <div className="space-y-3">
                            <div>
                                <span className="text-gray-600">Créé le :</span>{' '}
                                {format(new Date(folder.createdAt), 'dd/MM/yyyy')}
                            </div>
                            <div>
                                <span className="text-gray-600">Visibilité :</span>{' '}
                                {folder.settings.visibility}
                            </div>
                            <div>
                                <span className="text-gray-600">Description :</span>
                                <p className="mt-1 text-gray-800">{folder.description}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="text-lg font-medium mb-3">Auteur</h2>
                        <UserInfoDisplay
                            userId={folder.author}
                            displayMode="full"
                            onError={(error) => console.error('Erreur UserInfoDisplay:', error)}
                        />
                    </div>
                </div>
            </div>
            <FolderMetadata metadata={folder.metadata || defaultMetadata} />
        </div>
    );
};

export default FolderDetails;
