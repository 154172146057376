import { useState, useEffect } from 'react';

interface Settings {
  maintenanceMode: boolean;
  allowRegistrations: boolean;
}

const Settings = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [allowRegistrations, setAllowRegistrations] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user.token;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const response = await fetch(
          `${process.env.REACT_APP_URL_API_MACADAMIA}/settings`,
          config
        );

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des paramètres');
        }

        const data = await response.json();
        setMaintenanceMode(data.maintenanceMode);
        setAllowRegistrations(data.allowRegistrations);
      } catch (error) {
        console.error('Erreur:', error);
        setError('Erreur lors du chargement des paramètres');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleMaintenanceToggle = () => {
    setMaintenanceMode(!maintenanceMode);
  };

  const handleRegistrationsToggle = () => {
    setAllowRegistrations(!allowRegistrations);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError('');

    try {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const token = user.token;

      const response = await fetch(
        `${process.env.REACT_APP_URL_API_MACADAMIA}/settings`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            maintenanceMode,
            allowRegistrations,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde');
      }

      console.log('Paramètres sauvegardés avec succès');
    } catch (error) {
      console.error('Erreur:', error);
      setError('Erreur lors de la sauvegarde des paramètres');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <div className="p-8">Chargement...</div>;
  }

  return (
    <div className="p-8">
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <h1 className="text-xl font-platypi text-mcdm-marron-500 mb-6">Paramètres système</h1>

      <div className="bg-white p-6 rounded-lg shadow space-y-6">
        {/* Toggle Mode Maintenance */}
        <div className="flex items-center justify-between">
          <div>
            <h3 className="font-medium text-mcdm-marron-500">
              Mode maintenance : {maintenanceMode ? 'ON' : 'OFF'}
            </h3>
            <p className="text-sm text-mcdm-marron-400">
              {maintenanceMode
                ? "Le site est actuellement en maintenance"
                : "Le site est actuellement en ligne"
              }
            </p>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={maintenanceMode}
              onChange={handleMaintenanceToggle}
              className="sr-only peer"
              disabled={isSaving}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-mcdm-vert-100 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-mcdm-vert-400"></div>
          </label>
        </div>

        {/* Toggle Inscriptions */}
        <div className="flex items-center justify-between pt-4 border-t">
          <div>
            <h3 className="font-medium text-mcdm-marron-500">
              Gestion des inscriptions : {allowRegistrations ? 'ON' : 'OFF'}
            </h3>
            <p className="text-sm text-mcdm-marron-400">
              {allowRegistrations
                ? "Les inscriptions sont actuellement autorisées"
                : "Les inscriptions sont actuellement fermées"
              }
            </p>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={allowRegistrations}
              onChange={handleRegistrationsToggle}
              className="sr-only peer"
              disabled={isSaving}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-mcdm-vert-100 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-mcdm-vert-400"></div>
          </label>
        </div>

        {/* Bouton Enregistrer */}
        <div className="pt-6 border-t">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className={`px-4 py-2 text-white rounded-lg transition-colors duration-200 font-medium ${isSaving
                ? 'bg-mcdm-vert-300 cursor-not-allowed'
                : 'bg-mcdm-vert-400 hover:bg-mcdm-vert-500'
              }`}
          >
            {isSaving ? 'Enregistrement...' : 'Enregistrer'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;