import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

interface Log {
  _id: string;
  userId: string;
  timestamp: Date;
  type: string;
  category: string;
  subCategory: string;
  details: string | object;
  ipAddress?: string;
  userAgent?: string;
  sessionID?: string;
  outcome: string;
}

const Logs: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user.token;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.get(
          `${process.env.REACT_APP_URL_API_MACADAMIA}/logs?sortOrder=${sortOrder}`,
          config
        );
        setLogs(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des logs:', error);
      }
    };

    fetchLogs();
  }, [sortOrder]);

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold mb-4">Logs</h1>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">Date</th>
              <th scope="col" className="py-3 px-6">User</th>
              <th scope="col" className="py-3 px-6">Type</th>
              <th scope="col" className="py-3 px-6">Catégorie</th>
              <th scope="col" className="py-3 px-6">Sous-catégorie</th>
              <th scope="col" className="py-3 px-6">IP</th>
              <th scope="col" className="py-3 px-6">Résultat</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log._id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">
                  {format(new Date(log.timestamp), 'dd/MM/yyyy HH:mm')}
                </td>
                <td className="py-4 px-6">{log.userId}</td>
                <td className="py-4 px-6">{log.type}</td>
                <td className="py-4 px-6">{log.category}</td>
                <td className="py-4 px-6">{log.subCategory}</td>
                <td className="py-4 px-6">{log.ipAddress}</td>
                <td className="py-4 px-6">
                  <span className={`px-2 py-1 rounded-full text-xs ${log.outcome === 'Success' ? 'bg-green-100 text-green-800' :
                      log.outcome === 'Failure' ? 'bg-red-100 text-red-800' :
                        'bg-gray-100 text-gray-800'
                    }`}>
                    {log.outcome}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Logs;