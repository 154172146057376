import { LoginResponse, LoginRequest, ApiError, User } from '../types/login';

const API_URL = `${process.env.REACT_APP_URL_API_MACADAMIA}/users/`;

class AuthenticationError extends Error {
  constructor(public status: number, message: string) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    console.log('Tentative de connexion pour:', email);

    const loginRequest: LoginRequest = {
      email,
      password
    };

    const response = await fetch(`${API_URL}login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginRequest),
    });

    const data = await response.json();

    if (!response.ok) {
      const error = data as ApiError;
      throw new AuthenticationError(
        response.status,
        error.message || 'Erreur lors de la connexion'
      );
    }

    const loginResponse = data as LoginResponse;

    if (loginResponse.token) {
      console.log('Token reçu:', loginResponse.token);
      localStorage.setItem('user', JSON.stringify(loginResponse));
      window.dispatchEvent(new Event('storageChange'));
    }

    return loginResponse;
  } catch (error) {
    console.error('Erreur lors de la connexion:', error);

    if (error instanceof AuthenticationError) {
      throw error;
    }

    if (error instanceof TypeError) {
      throw new AuthenticationError(
        0,
        'Erreur de connexion au serveur. Veuillez vérifier votre connexion internet.'
      );
    }

    throw new AuthenticationError(500, 'Une erreur inattendue est survenue');
  }
};

export const getToken = (): string | null => {
  try {
    const user = localStorage.getItem('user');
    if (!user) return null;

    const userData = JSON.parse(user) as LoginResponse;
    return userData.token;
  } catch (error) {
    console.error('Erreur lors de la récupération du token:', error);
    return null;
  }
};

export const getCurrentUser = (): User | null => {
  try {
    const user = localStorage.getItem('user');
    if (!user) return null;

    const userData = JSON.parse(user) as LoginResponse;
    return userData.user;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'utilisateur:', error);
    return null;
  }
};

export const logout = (): void => {
  try {
    localStorage.removeItem('user');
    window.dispatchEvent(new Event('storageChange'));
  } catch (error) {
    console.error('Erreur lors de la déconnexion:', error);
  }
};

export const isAuthenticated = (): boolean => {
  return getToken() !== null && getCurrentUser() !== null;
};