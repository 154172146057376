export const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white/80">
      <div className="flex flex-col items-center gap-2">
        <p className="text-sm text-gray-500">Chargement...</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;