import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import UserInfoDisplay from '../../common/UserInfoDisplay';

interface Folder {
  _id: string;
  title: string;
  author: string;
  createdAt: Date;
  settings: {
    visibility: string;
  };
}

const SkeletonTable: React.FC = () => {
  return (
    <div className="p-8 animate-pulse">
      <h1 className="text-xl font-semibold mb-4 bg-gray-300 rounded w-32 h-6"></h1>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full text-sm text-left">
          <thead>
            <tr>
              {['Titre', 'Auteur', 'Date de création', 'Visibilité'].map((_, index) => (
                <th key={index} className="py-3 px-6">
                  <div className="bg-gray-300 rounded h-4 w-24"></div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(5)].map((_, rowIndex) => (
              <tr key={rowIndex} className="bg-white border-b">
                {Array(4)
                  .fill(null)
                  .map((_, colIndex) => (
                    <td key={colIndex} className="py-4 px-6">
                      <div className="bg-gray-300 rounded h-4 w-full"></div>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Folders: React.FC = () => {
  const navigate = useNavigate();
  const [folders, setFolders] = useState<Folder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = user.token;

        const response = await fetch(
          `${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Erreur réseau lors de la récupération des dossiers');
        }

        const data = await response.json();
        setFolders(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des dossiers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFolders();
  }, []);

  if (loading) {
    return <SkeletonTable />;
  }

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold mb-4">Dossiers</h1>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">Titre</th>
              <th scope="col" className="py-3 px-6">Auteur</th>
              <th scope="col" className="py-3 px-6">Date de création</th>
              <th scope="col" className="py-3 px-6">Visibilité</th>
            </tr>
          </thead>
          <tbody>
            {folders.map((folder) => (
              <tr
                key={folder._id}
                className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                onClick={() => navigate(`/folders/${folder._id}`)}
              >
                <td className="py-4 px-6">{folder.title}</td>
                <td className="py-4 px-6">
                  <UserInfoDisplay 
                    userId={folder.author} 
                    displayMode="compact" 
                    onError={(error) => console.error('Erreur UserInfoDisplay:', error)}
                  />
                </td>
                <td className="py-4 px-6">
                  {format(new Date(folder.createdAt), 'dd/MM/yyyy')}
                </td>
                <td className="py-4 px-6">{folder.settings.visibility}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Folders;