import React, { useState, useEffect } from 'react';

interface UserInfoProps {
    userId: string;
    displayMode?: 'compact' | 'full';
    onError?: (error: Error) => void;
}

interface UserData {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
    civilite: string;
    profilePicture?: string;
    role: string;
}

const UserInfoDisplay = ({ userId, displayMode = 'compact', onError }: UserInfoProps) => {
    const [userData, setUserData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user') || '{}');
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/users/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données utilisateur');
                }

                const data = await response.json();
                setUserData(data);
            } catch (err) {
                const error = err instanceof Error ? err : new Error('Erreur inconnue');
                setError(error);
                if (onError) onError(error);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [userId, onError]);

    if (loading) {
        return (
            <div className="flex items-center space-x-2 animate-pulse">
                <div className="w-8 h-8 rounded-full bg-gray-200" />
                <div className="h-4 bg-gray-200 rounded w-24" />
            </div>
        );
    }

    if (error || !userData) {
        return (
            <div className="flex items-center space-x-2 text-gray-500">
                <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                    <span className="text-xs">?</span>
                </div>
                <span>Utilisateur inconnu</span>
            </div>
        );
    }

    if (displayMode === 'compact') {
        return (
            <div className="flex items-center space-x-2">
                {userData.profilePicture ? (
                    <img
                        src={userData.profilePicture}
                        alt={`${userData.firstName} ${userData.lastName}`}
                        className="w-8 h-8 rounded-full object-cover"
                    />
                ) : (
                    <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                        <span className="text-gray-500 text-sm">
                            {userData.firstName[0]}{userData.lastName[0]}
                        </span>
                    </div>
                )}
                <div className="flex flex-col">
                    <span className="font-medium">
                        {userData.firstName} {userData.lastName}
                    </span>
                    <span className="text-xs text-gray-500">{userData.email}</span>
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-start space-x-4 p-4 rounded-lg border">
            {userData.profilePicture ? (
                <img
                    src={userData.profilePicture}
                    alt={`${userData.firstName} ${userData.lastName}`}
                    className="w-12 h-12 rounded-full object-cover"
                />
            ) : (
                <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                    <span className="text-gray-500">
                        {userData.firstName[0]}{userData.lastName[0]}
                    </span>
                </div>
            )}
            <div className="flex flex-col">
                <span className="font-medium text-lg">
                    {userData.civilite} {userData.firstName} {userData.lastName}
                </span>
                <span className="text-gray-500">{userData.email}</span>
                <span className="text-sm text-gray-500 mt-1">
                    Rôle : <span className="font-medium">{userData.role}</span>
                </span>
            </div>
        </div>
    );
};

export default UserInfoDisplay;