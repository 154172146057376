import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserInfoDisplay from '../../common/UserInfoDisplay';
import { format } from 'date-fns';
import FolderMetadata from './FolderMetadata';

import {
    IMetadata,
    IClassification,
    ILegalConcepts,
    IStructure,
    ILegalReferences,
    IRelatedConcept,
    ISemanticAnalysis,
    IPracticalApplication,
    IPedagogicalElements,
    IContentQuality,
    IFolderMetadata,
} from '../../../types/folderMetadata';


interface FolderMetadataComplete {
    metadata: IMetadata;
    classification: IClassification;
    legalConcepts: ILegalConcepts;
    structure: IStructure;
    legalReferences: ILegalReferences;
    relatedConcepts: IRelatedConcept[];
    semanticAnalysis: ISemanticAnalysis;
    practicalApplications: IPracticalApplication[];
    pedagogicalElements: IPedagogicalElements;
    contentQuality: IContentQuality;
}

interface Folder {
    _id: string;
    title: string;
    author: string;
    description: string;
    createdAt: Date;
    settings: {
        visibility: string;
    };
    metadata: IFolderMetadata | null;
}

const FolderDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [folder, setFolder] = useState<Folder | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const defaultMetadata: IFolderMetadata = {
        metadata: {
          title: '∅',
          type: '∅',
          domain: '∅',
          subDomain: '∅',
          level: '∅',
          lastUpdated: new Date(),
          language: '∅',
        },
        classification: {
          mainCategory: '∅',
          subCategories: [],
          nature: '∅',
        },
        legalConcepts: {
          primary: [],
          secondary: [],
        },
        structure: {
          sections: [],
        },
        legalReferences: {
          codes: [],
          jurisprudence: [],
        },
        relatedConcepts: [],
        semanticAnalysis: {
          keyPhrases: [],
          definitions: [],
        },
        practicalApplications: [],
        pedagogicalElements: {
          difficulty: '∅',
          prerequisites: [],
          learningObjectives: [],
        },
        contentQuality: {
          completeness: 0,
          clarity: 0,
          technicalAccuracy: 0,
          practicalRelevance: 0,
        },
      };
        

    useEffect(() => {
        const fetchFolderDetails = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user') || '{}');
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/dossiers/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des détails du dossier');
                }

                const data = await response.json();
                setFolder(data);
            } catch (error) {
                console.error('Erreur:', error);
                setError(error instanceof Error ? error.message : 'Une erreur est survenue');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchFolderDetails();
        }
    }, [id]);

    if (loading) {
        return (
            <div className="p-8 animate-pulse">
                <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
                <div className="space-y-4">
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-8">
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
                    {error}
                </div>
            </div>
        );
    }

    if (!folder) {
        return (
            <div className="p-8">
                <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded">
                    Dossier non trouvé
                </div>
            </div>
        );
    }

    return (
        <div className="p-8">
            <div className="bg-white rounded-lg shadow-md p-6">
                <h1 className="text-2xl font-platypi mb-4">{folder.title}</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h2 className="text-lg font-medium mb-3">Informations générales</h2>
                        <div className="space-y-3">
                            <div>
                                <span className="text-gray-600">Créé le :</span>{' '}
                                {format(new Date(folder.createdAt), 'dd/MM/yyyy')}
                            </div>
                            <div>
                                <span className="text-gray-600">Visibilité :</span>{' '}
                                {folder.settings.visibility}
                            </div>
                            <div>
                                <span className="text-gray-600">Description :</span>
                                <p className="mt-1 text-gray-800">{folder.description}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="text-lg font-medium mb-3">Auteur</h2>
                        <UserInfoDisplay
                            userId={folder.author}
                            displayMode="full"
                            onError={(error) => console.error('Erreur UserInfoDisplay:', error)}
                        />
                    </div>
                </div>
            </div>
            <FolderMetadata metadata={folder.metadata || defaultMetadata} />
        </div>
    );
};

export default FolderDetails;